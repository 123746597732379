<!-- <div class=" h-100 d-flex">
  <div class="login position-absolute overflow-hidden flex-wrap align-items-center">
    <div class="top-0 ps-5 pt-1">
      <img src="../../../assets/logo/logo 1.svg" width="70" alt="">
    </div>
    <div class="ContDiv position-relative h-100 ">
      <div class="justify-content-between h-100 d-lg-flex d-md-block d-block">

        <div class="sideImage col-md-5 col-12 ms-5 mt-4 ">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let itemImg of AuthImages">
              <img [src]="itemImg" class="logImg"
                width="1200" alt="">
              </ng-template>

          </owl-carousel-o>
        </div>

        <div class=" col-md-5 col-12">

          <div class="login  flex-wrap align-items-center">
            <div class="ContDiv  ">
              <div class="background  ">
                <div class="loginBody  resIn0 pb-5 pt-3 col-md-6 col-12  text-start ">
                  <div class=" resIn0 childLog px-2 d-flex flex-column py-3 text-start ">
                    <h3 class="  text-black width-280px mb-4 px-lg-0 ">
                      Welcome to <span class="d-block fw-bold " style="color: #6358DC;">Fatora Pro</span></h3>
                      <router-outlet></router-outlet>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<!--  new design -->

<div class="d-flex flex-column justify-content-between align-items-between h-100">


<div class="top-0 px-0 text-center text-lg-start pt-4 pt-lg-3 px-5">
  <img src="../../../assets/logo/logo 1.svg" width="90" alt="">
</div>

<div class="d-flex align-items-center justify-content-xxl-center flex-column flex-fill">
 
  <!-- container-fluid  -->
  <div class="d-flex w-100">  
    <div class="sliderWidth">
      <ng-container *ngIf="owl">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let itemImg of AuthImages">
            <img [src]="itemImg" class="logImg mx-auto" alt="" [style.width.%]="70">
            </ng-template>
    
        </owl-carousel-o>
      </ng-container>
    </div>
  
    <!-- auth pages -->
  
    <div class="align-items-center authCont d-flex justify-content-center login p-4">
      <div class="ContDiv  w-100">
        <!-- <div class=" w-100 "> background -->
          <div class="loginBody p-0 p-3 p-md-1 p-xl-2 p-xxl-5">
            <div class=" d-flex flex-column px-2 text-start">
              <h3 class="  text-black width-280px px-lg-0 "><!-- ps-3-->
                {{'LOGIN.WELCOMETO'|translate}}<span class="d-block fw-bold darkBlue-text"> {{'LOGIN.VATLEX'|translate}}</span></h3>
                <router-outlet></router-outlet>
              </div>
          </div>
        <!-- </div> -->
      </div>
    </div>
  
  </div>
</div>
</div>
