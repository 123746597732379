 
 <router-outlet></router-outlet>

 <!-- <ngx-ui-loader loaderId="loader" fgsColor="#5C60F5" pbColor="#5C60F5" fgsPosition="center-center"
  fgsType="folding-cube" overlayColor="#ffffffab" pbDirection="rtl"  
  bgsPosition="center-center" bgsType="folding-cube" [fgsSize]="70" [pbThickness]="3" [hasProgressBar]="true"
   overlayBorderRadius="8px">
    </ngx-ui-loader> -->

 <!-- todo:vatlex     -->
 <ngx-ui-loader loaderId="loader" fgsColor="#007AFF" pbColor="#007AFF" fgsPosition="center-center"
  fgsType="folding-cube" overlayColor="#ffffffab" pbDirection="rtl"  
  bgsPosition="center-center" bgsType="folding-cube" [fgsSize]="70" [pbThickness]="3" [hasProgressBar]="true"
   overlayBorderRadius="8px">
    </ngx-ui-loader>