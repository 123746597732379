<header>
  <div class="d-flex justify-content-end align-items-center mainDiv p-1 p-lg-3 m-auto" style="z-index: 2;">

    <!-- <div  routerLink="/landing" class="">
      <img class="cursor-pointer" 
      style="width: 45%; padding-block: 5%;"
      src="./assets/logo/logo 1.svg" alt="">
    </div> -->

    <div class="notification-container " *ngIf="role == 'user'">
      <div (click)="notificationsDropdown = !notificationsDropdown; MarkAllasRead()" class="notification ">
        <button type="button" class="border-0 h-100 p-0 position-relative bg-transparent">
          <img src="./assets/images/sidenav/bell.svg" width="35" alt="bell notif">
          <span class="position-absolute notificationBadge tada" *ngIf="NotificationCount > 0">
            {{ NotificationCount }}
          </span>
        </button>
      </div>
      <div *ngIf="notificationsDropdown" class="dropdown" [ngClass]="{
          English_badge: language === 'en',
          Arabic_badge: language === 'ar'
        }">
        <div class="notifications-container p-2">
          <div class="w-100 p-3 text-center" style="border-bottom: 1px solid gray;"
            *ngIf="notificationsService.notificationsList.length == 0"> {{'MESSAGES.NOTIFICATIONLISTEMPTY' | translate}}
          </div>
          <div class="d-flex gap-3 align-items-center px-3 notificationItem cursor-pointer"
            *ngFor="let notification of notificationsService.notificationsList;let i = index"
            (click)="showNotification(notification.id,i)">
            <div class="unreadCont">
              <div class="unreadNotif w-100 h-100" *ngIf="!notification.seen"></div>
              <div class="readNotif w-100 h-100" *ngIf="notification.seen"></div>
            </div>
            <div class="w-100">
              <div class="d-flex justify-content-between align-items-center">
                <p class="fw-bold m-0"> {{ notification.title }} </p>
                <p class="fs-12 m-0 opacity-75"> {{ notification.created_at }} </p>
              </div>
              <p class="m-0 p-0 pt-2">{{ notification.body | slice:0:40 }}{{ notification.body.length > 40 ? '...' : ''
                }}</p>
            </div>
          </div>

          <p class="text-center fw-bold darkBlue-text mt-3 mb-2 cursor-pointer" (click)="notificationsDropdown = false">
           <a  routerLink="/user/Notifications/All-notifications"> {{'COMMON.VIEWALL'|translate}} </a>  
          </p>
        </div>
      </div>
    </div>

    <div class="dropdown my-auto pe-2-lg">
      <button class="btn dropdown-toggle fw-bold purple_Text font-main langBtn px-0 px-md-3" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <span class="fullLang"> {{'LANDING.LANG'|translate}} </span>
        <span class="HalfLang"> {{'LANDING.HALFLANG'|translate}} </span>
        
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" (click)="changeLang('en')"> English </a></li>
        <li><a class="dropdown-item" (click)="changeLang('ar')"> العربية </a></li>
      </ul>
    </div>

    <div class="dropdown my-auto ps-2 pe-2 d-flex">
      <button class="btn dropdown-toggle purple_Text font-main UserName" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        {{name}}
      </button>

      <div data-bs-toggle="dropdown">
        <img *ngIf="!profileImage" src="./assets/logo/profile_img.svg" width="48" height="48" alt=""
          class="rounded-circle" style="object-fit: cover;" 
          aria-expanded="false">
        <img *ngIf="profileImage" [src]="profileImage" width="48" height="48" alt="" class="rounded-circle"
          style="object-fit: cover;">
      </div>

      <ul class="dropdown-menu">
        <li style="border-bottom: 1px solid gainsboro;">
          <h6 class="dropdown-header d-flex align-items-center">
            <img *ngIf="!profileImage" src="./assets/logo/profile_img.svg" width="48" height="48" alt="">
            <img *ngIf="profileImage" [src]="profileImage" width="48" height="48" alt="">
            <div class="dropdown-user-details text-end text-end ms-3">
              <div class="dropdown-user-details-name">{{name}}</div>
              <div class="dropdown-user-details-email">{{email}}</div>
            </div>
          </h6>

        </li>
        <li class="cursor-pointer" (click)="navigateToProfile()"><a class="dropdown-item d-flex">
            <div class="dropdown-item-icon">
              <i class="fas fa-user-cog me-2"></i>
            </div>
            {{'COMMON.ACCOUNT'|translate}}
          </a></li>


        <li class="cursor-pointer">
          <a class="dropdown-item d-flex" routerLink="/landing">
            <div class="dropdown-item-icon">
              <i class="fas fa-home me-2"> </i>
            </div>
             {{'LANDING.HOME'|translate}}
          </a></li>
        <li class="cursor-pointer">
          <a class="dropdown-item d-flex" href="https://fatorah-pro.com/auth/login" target="_blank">
            <div class="dropdown-item-icon">
              <img src="./assets/images/sidenav/world.svg" class="me-2" width="20" alt="">
            </div>
            {{'COMMON.PREVWEBSITE'|translate}}
          </a>
        </li>
        <li class="cursor-pointer purplebackground" (click)="logout()"><a style="color: white;"
            class="dropdown-item d-flex  logout">
            <div class="dropdown-item-icon me-2">
              <i class="fas fa-sign-out-alt"></i>
            </div>
            {{'LANDING.LOGOUT'|translate}}
          </a></li>
      </ul>
      
    </div>

    <!-- <div>
      <img *ngIf="!profileImage" src="./assets/logo/profile_img.svg" width="48" height="48" alt=""
        class="rounded-circle" style="object-fit: cover;" data-bs-toggle="dropdown"
        aria-expanded="false">
      <img *ngIf="profileImage" [src]="profileImage" width="48" height="48" alt="" class="rounded-circle"
        style="object-fit: cover;">
    </div> -->
  </div>
</header>