import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router , Event, NavigationEnd} from '@angular/router';
import { permission } from '@modules/permissions';
import { AuthService } from '@services/auth.service';
import { ChangeLanguageService } from '@services/changeLanguage.service';
import { GeneralService } from '@services/general.service';
import { GeneralUserDataService } from '@services/generalUserData.service';
import { NotificationsService } from '@services/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sideNavbar',
  templateUrl: './sideNavbar.component.html',
  styleUrls: ['./sideNavbar.component.scss']
})
export class SideNavbarComponent implements OnInit {
  activeURL = ''
  activeControl:boolean=false
  activeInvoices:boolean=false
  activePurchases:boolean=false
  activeSettings:boolean=false
  activeUser:boolean=false
  activeSupplier:boolean = false
  activeProducts:boolean=false
  activeBranches:boolean=false
  activePrices:boolean=false
  activeReports:boolean=false
  activeReceiptv:boolean=false
  activeAllPaymentLinks:boolean=false
  activeSubscription:boolean = false;
  activeNotification:boolean = false;
  

  activeBalanceTransferRequest:boolean=false
  activeAdminUser:boolean=false
  activeAdminRoles:boolean=false
  activeAdminCoupons:boolean=false
  activeAdminSubscriptionPlans:boolean=false
  activeAdminSales:boolean=false
  activeAdminPaymentlinks:boolean=false
  activeRevenues:boolean=false
  activeAdminBlogs:boolean=false
  activeAdminMessages:boolean=false
  activeAdminTemplates:boolean=false
  activeAdminSettings:boolean=false
  activeAdminReports:boolean=false
  activeAdminNotification:boolean = false;
  dashboredColor:any
  language:any

  showSideNav = true;
  isMobile = false;
  permissions:permission = {} as permission;
  active_features:string[] = [];

  active_purchase:Boolean = false;
  private subs=new Subscription();

  constructor(private router: Router,private changeLang:ChangeLanguageService,
    private authService: AuthService, private notificationsService:NotificationsService,
    private generalService:GeneralService) {
      this.checkScreenSize();
     }


  checkScreenSize() {
    this.isMobile = window.innerWidth <= 1024; // or any other size you consider as mobile
  }

  closeSideNav(){
    if(this.isMobile){
      var offcanvasScrolling = document.getElementById('offcanvasScrolling');
      offcanvasScrolling?.classList.remove('show');
    }
  }
  ngOnInit() {

    this.authService.UserObsrv$.subscribe({
      next: (res: any) => {
  
        let userInfoString = localStorage.getItem('UserObj')

        if (res) {
          let userData = res;
          localStorage.setItem('UserObj', JSON.stringify(userData));

          this.name = userData?.name
          this.email = userData?.email
          this.active_purchase = userData?.active_purchase
          this.profileImage = userData?.media?.avatar
          this.role=userData?.role;
          this.active_features = userData?.active_features;
          if(!userData?.permissions){
            this.authService.signOut()
          }else{
            this.permissions = userData?.permissions;
          }
          
                
        } else if (userInfoString) {
          let userData = JSON.parse(userInfoString);

          this.name = userData?.name
          this.email = userData?.email
          this.active_purchase = userData?.active_purchase;
          this.profileImage = userData?.media?.avatar
          this.role=userData?.role;
          this.active_features = userData?.active_features;

          if(!userData?.permissions){
            this.authService.signOut()
          }else{
          this.permissions = userData?.permissions;
          }

        }
      }
    })

    setTimeout(() => {
      var offcanvasScrolling = document.getElementById('offcanvasScrolling');
      if(window.innerWidth > 1024){
        offcanvasScrolling?.classList.add('show');
      }else{
        offcanvasScrolling?.classList.remove('show');
      }
    }, 200);
    this.getGeneralData()
    this.language=this.changeLang.local_lenguage
    if(localStorage.getItem('dashboardColor')){
      this.dashboredColor=localStorage.getItem('dashboardColor')
      document.documentElement.style.setProperty('--dashboredColor',this.dashboredColor);
      document.documentElement.style.setProperty('--whiteText','#ffff');

    }
    var offcanvasScrolling = document.getElementById('offcanvasScrolling');
    

    this.activeURL = this.router.url
    if(this.role=='user'){
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.activeURL = event.url;
          this.checkUserActiveUrl(this.activeURL);
          if (this.activeURL.includes('/viewOnly')) {
            this.showSideNav = false;
          }
        }
      });
      this.checkUserActiveUrl(this.activeURL)
       if (this.activeURL.includes('/viewOnly')) {
        this.showSideNav = false;
      }
    }
    if(this.role=='admin'){
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.activeURL = event.url;
          this.checkAdminActiveUrl(this.activeURL);
        }
      });
      this.checkAdminActiveUrl(this.activeURL);
      if (this.activeURL.includes('/viewOnly')) {
        this.showSideNav = false;
      }
    }

  }

  checkUserActiveUrl(activeURL:string) {
    setTimeout(() => {
    if(this.role && this.role == 'user' && this.activeURL.includes('/user')){
        this.notificationsService.getNotificationsData();
      }
    }, 500);
    const activeStates: { [key: string]: string[] } = {
        activeControl: ['/control'],
        activeInvoices: ['/All-invoices', '/All-credit-notes', '/Add-invoices', '/credit-note-details/'],
        activePurchases: ['/purchases'],
        activeUser: ['/users'],
        activeSupplier: ['/suppliers-list'],
        activeProducts: ['/products'],
        activeBranches: ['/branches'],
        activePrices: ['/prices'],
        activeReports: ['/reports'],
        activeReceiptv: ['/All-receipt-voucher'],
        activeAllPaymentLinks: ['/All-payment-links'],
        activeBalanceTransferRequest: ['/balance-transfer-request'],
        activeSubscription: ['/Subscription/subscription-payment'],
        activeNotification: ['/Notifications'],
        activeSettings: ['/commercial-account-details', '/design-and-colors']
    };
    Object.keys(activeStates).forEach(key => (this as any)[key] = false);

    for (const [property, urls] of Object.entries(activeStates)) {
      if (urls.some(url => activeURL.includes(url))) {
          (this as any)[property] = true;
          if (['activeControl', 'activeUser', 'activeSupplier', 'activeProducts', 'activeBranches' , 'activePrices', 'activeAllPaymentLinks', 'activeBalanceTransferRequest'].includes(property)) {
              this.closeAllAccordion(5);
          }
          break; 
      }
  }
  }


  checkAdminActiveUrl(activeURL:string){
    // reset
      this.activeControl = false;
      this.activeUser = false;
      this.activeAdminUser = false;
      this.activeAdminRoles = false;
      this.activeAdminCoupons = false;
      this.activeAdminSubscriptionPlans = false;
      this.activeAdminSales = false;
      this.activeAdminPaymentlinks = false;
      this.activeRevenues = false;
      this.activeAdminBlogs = false;
      this.activeAdminMessages = false;
      this.activeAdminTemplates = false;
      this.activeAdminSettings = false;
      this.activeAdminReports = false;
      this.activeAdminNotification = false;

      // check
      if (activeURL.includes('/control')) {
        this.activeControl = true;
    } else if (activeURL.includes('/Admin-Reports')) {
        this.activeAdminReports = true;
    } else if (activeURL.includes('/All-users')) {
        this.activeUser = true;
    } else if (activeURL.includes('/All-administrative-users')) {
        this.activeAdminUser = true;
    } else if (activeURL.includes('role')) {
        this.activeAdminRoles = true;
    } else if (activeURL.includes('coupons')) {
        this.activeAdminCoupons = true;
    } else if (activeURL.includes('subscription-plans')) {
        this.activeAdminSubscriptionPlans = true;
    } else if (['credit-notes', 'debit-notes', 'invoices', 'receipt-vouchers', 'quotations'].some(path => activeURL.includes(path))) {
        this.activeAdminSales = true;
    } else if (activeURL.includes('/payment-links')) {
        this.activeAdminPaymentlinks = true;
    } else if (activeURL.includes('/revenue')) {
        this.activeRevenues = true;
    } else if (activeURL.includes('/blogs')) {
        this.activeAdminBlogs = true;
    } else if (activeURL.includes('/templates')) {
        this.activeAdminTemplates = true;
    } else if (activeURL.includes('/settings') || activeURL.includes('email')) {
        this.activeAdminSettings = true;
    } else if (activeURL.includes('/contact-us-messages') && !activeURL.includes('notification') && !activeURL.includes('email')) {
        this.activeAdminMessages = true;
    } else if (activeURL.includes('/contact-us-messages') && activeURL.includes('notification')) {
        this.activeAdminNotification = true;
    }
  }
 
  goToAllPaymentLinks(){
    this.router.navigate(['/user/payment-links/All-payment-links']).then(()=>{
      window.location.reload()
    })
  }
  goToBalanceTransferRequest(){
    this.router.navigate(['/user/payment-links/balance-transfer-request']).then(()=>{
      window.location.reload()
    })
  }
  @HostListener('window:resize', ['$event'])
   onResize(event:any) {
    this.checkScreenSize();
    setTimeout(() => {
      var offcanvasScrolling = document.getElementById('offcanvasScrolling');
      if(window.innerWidth > 1024){
        offcanvasScrolling?.classList.add('show');
      }else{
        offcanvasScrolling?.classList.remove('show');
      }
    }, 200);
  
  }
  LogOut(){}

  @ViewChild('invoices') invoices!: ElementRef<HTMLElement>;
  @ViewChild('purchases') purchases!: ElementRef<HTMLElement>;
  @ViewChild('reports') reports!: ElementRef<HTMLElement>;
  @ViewChild('settings') settings!: ElementRef<HTMLElement>;
  @ViewChild('sales') sales!: ElementRef<HTMLElement>;
  isOpenInvoices=false
  isOpenPurchases=false
  isOpenReports=false
  isOpenSettings=false

  closeAllAccordion(index:number){
    // invoice
     if(index==1){
      this.isOpenInvoices=!this.isOpenInvoices;
      if(this.isOpenInvoices){
        if(this.isOpenPurchases){
          let Purchases: HTMLElement = this.purchases.nativeElement;
          Purchases.click();
        }
        if(this.isOpenReports){
          let Reports: HTMLElement = this.reports.nativeElement;
          Reports.click();
        }
        if(this.isOpenSettings){
          let settings: HTMLElement = this.settings.nativeElement;
          settings.click();
        }

      }
     }
    //  Purchases
     if(index==2){
      this.isOpenPurchases=!this.isOpenPurchases;
      if(this.isOpenPurchases){
        if(this.isOpenInvoices){
          let Invoices: HTMLElement = this.invoices.nativeElement;
          Invoices.click();
        }
        if(this.isOpenReports){
          let Reports: HTMLElement = this.reports.nativeElement;
          Reports.click();
        }
        if(this.isOpenSettings){
          let settings: HTMLElement = this.settings.nativeElement;
          settings.click();
        }

      }
     }
    //  Reports
     if(index==3){
      this.isOpenReports=!this.isOpenReports;
      if(this.isOpenReports){

        if(this.isOpenInvoices){
          let Invoices: HTMLElement = this.invoices.nativeElement;
          Invoices.click();
        }
        if(this.isOpenPurchases){
          let Purchases: HTMLElement = this.purchases.nativeElement;
          Purchases.click();
        }
        if(this.isOpenSettings){
          let settings: HTMLElement = this.settings.nativeElement;
          settings.click();
        }

      }
     }

    //  Settings
     if(index==4){
      this.isOpenSettings=!this.isOpenSettings;
      if(this.isOpenSettings){

        if(this.isOpenInvoices){
          let Invoices: HTMLElement = this.invoices.nativeElement;
          Invoices.click();
        }
        if(this.isOpenPurchases){
          let Purchases: HTMLElement = this.purchases.nativeElement;
          Purchases.click();
        }
        if(this.isOpenReports){
          let Reports: HTMLElement = this.reports.nativeElement;
          Reports.click();
        }

      }
     }
    //  any page else
     if(index==5){
        if(this.isOpenInvoices){
          let Invoices: HTMLElement = this.invoices.nativeElement;
          Invoices.click();
        }
        if(this.isOpenPurchases){
          let Purchases: HTMLElement = this.purchases.nativeElement;
          Purchases.click();
        }
        if(this.isOpenReports){
          let Reports: HTMLElement = this.reports.nativeElement;
          Reports.click();
        }
        if(this.isOpenSettings){
          let settings: HTMLElement = this.settings.nativeElement;
          settings.click();
        }
     }


  }

  name:string =''
  email:string =''
  profileImage:string =''
  role:any
  getGeneralData(){
    let generalData =  localStorage.getItem('UserObj');
    if(generalData){
      let userData = JSON.parse(generalData);
      this.name = userData?.name
      this.email = userData?.email
      this.profileImage = userData?.media?.avatar
      this.role=userData?.role
    }else{
      this.subs.add(this.generalService.getGeneralData().subscribe({
        next:(res)=>{
          this.name = res?.data?.name
          this.email = res?.data?.email
          this.profileImage = res?.data?.media?.avatar
          this.role=res?.data?.role
          localStorage.setItem('UserObj',JSON.stringify(res?.user))
        }
      }));
    }
    
   }

   NavigateToURL(url:string){
    this.router.navigate(['/user/invoices/add-credit-note/create', '-1'], { queryParams: { reload: new Date().getTime() } });
   }
}
