import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { userNotification } from '@modules/notification';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private NotificationCountSubject = new BehaviorSubject<number>(0);
  public NotificationCount$ = this.NotificationCountSubject.asObservable();
  
// public notificationsCount:number = 0;
public notificationsList:userNotification[] = []
constructor(private http:HttpService,
  private auth: AuthService,
) { 
  
}
updateNotificationCount(notificationsCount: number) {
  this.NotificationCountSubject.next(notificationsCount)
}
getNotificationsData(){
  if(this.auth.getUserObj().role == 'user'){
    this.http.getReq('api/dashboard/notifications/latest').subscribe({
      next:res=>{
        
        this.updateNotificationCount(res.notification_count)
        this.notificationsList = res.data;
      },
     })
  }
}

  markAsRead(id:any){
    this.http.putReq(`api/dashboard/notifications/${id}`).subscribe(
      {
        next:(res)=>{
          this.getNotificationsData()
        }
      }
    ); 
  }
}
