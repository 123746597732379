import { Component, HostListener, OnInit } from '@angular/core';
import { ChangeLanguageService } from '@services/changeLanguage.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  language:string = 'ar'
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 10000,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    nav: false,
    items:1,
    margin:20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items:1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
   
  }

  AuthImages = [
    "./assets/images/login/1.svg",
    "./assets/images/login/2.svg",
    "./assets/images/login/3.svg",
    "./assets/images/login/4.svg",
    "./assets/images/login/5.svg"
  ]
  constructor(private changelngServ:ChangeLanguageService) { }

  ngOnInit() {
    this.language=this.changelngServ.local_lenguage
    if(this.language === 'ar'){
      this.customOptions.rtl=true
    }
    else{
      this.customOptions.rtl=false
    }
  }

  owl = true;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.owl = false;
    setTimeout(() => {
      this.owl = true
    }, 200);
  }

}
